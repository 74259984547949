<template>
  <div class="pop">
    <van-popup v-model="show">
      <div class="container">
        <!-- <div class="title-container"> -->
          <!-- <van-image
              class="gonggao"
              width="80"
              :src="require('../image/gonggao.png')"
            /> -->
          <!-- {{ $t('最新通告') }} -->
        <!-- </div> -->
        <div class="content-container">
          <div v-if="typeCur === '1'">
            <div v-html="content"></div>
            <van-image
              style="margin-top: 30px;"
              width="100%"
              :src="imageUrl"
            />
          </div>
          <div v-else-if="typeCur === '2'">
            <van-image
              width="100%"
              :src="imageUrl"
            />
          </div>
          <div v-else-if="typeCur === '3'" v-html="content">
          </div>
        </div>
        <div class="footer-container">
          <van-button size="small" @click="show = false" type="default" block color="#0097B2">
            {{ $t('CANCEL') }}
          </van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { getPopupAdvertising } from '../../../api/home.js'
export default {
  name: 'pop',
  data () {
    return {
      // 类型1.图文 2.图片 3.文字
      type: {
        1: '图文',
        2: '图片',
        3: '文字'
      },
      show: false,
      typeCur: '1',
      imageUrl: '',
      content: '',
      url: ''
    }
  },
  methods: {
    async get () {
      const { data } = await getPopupAdvertising()
      if (data.data && data.code === 200) {
        this.typeCur = data.data.type
        this.imageUrl = data.data.imageUrl
        this.content = data.data.content
        this.url = data.data.url
        this.show = true
      } else {
        this.show = false
      }
      console.log(data)
    }
  },
  created () {
    // console.log(this.$store.state.token)
    if (this.$store.state.token) {
      this.get()
    }
  }
}
</script>
<style scoped>
.pop .container {
  width: 320px;
  min-height: 350px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.pop .container .title-container {
  position: relative;
  height: 60px;
  text-align: center;
  line-height: 60px;
  background-color: #fff;
  font-size: 18px;
  border-radius: 10px 10px 0 0;
}
.pop .container .title-container .gonggao {
  position: absolute;
  left: -18px;
  top: -22px;
}
.pop .container .content-container {
  height: 300px;
  padding: 10px;
  font-size: 16px;
  overflow-y: auto;
  word-wrap: break-word;
}
.pop .container .content-container div {
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.pop .container .footer-container {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 100px;
  background-color: #fff;
  border-radius: 0px 0px 10px 10px;
}
.pop >>> .van-popup {
  background-color: unset;
  overflow-y: unset;
}
.pop >>> .van-button__text {
  color: #000;
  font-weight: 600;
}
.pop >>> .van-image {
  margin-top: 10px;
  width: 100%;
}
.pop >>> .van-button__text {
  color: #fff;
}
</style>
