<template>
  <div class="home">
    <!-- <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <img :src="require('./img/t1.png')" height="100%" alt="" srcset="">
      </van-swipe-item>
      <van-swipe-item>
        <img :src="require('./img/t2.png')" height="100%" alt="" srcset="">
      </van-swipe-item>
      <van-swipe-item>
        <img :src="require('./img/t3.png')" height="100%" alt="" srcset="">
      </van-swipe-item>
      <van-swipe-item>
        <img :src="require('./img/t4.png')" height="100%" alt="" srcset="">
      </van-swipe-item>
      <van-swipe-item>
        <img :src="require('./img/t5.png')" height="100%" alt="" srcset="">
      </van-swipe-item>
    </van-swipe> -->
    <img class="adv" :src="require('./img/t1.png')" height="200" alt="" srcset="">
    <div class="headContainer">
      <div class="applyMoney">{{$t('APPLYMONEY')}}</div>
      <div class="money">
        <div class="showm">{{applyMoney}}</div>
        <van-button color="" @click="openM" class="chooseMoney" type="info" block>{{$t('EDITTHEAMOUNT')}}</van-button>
        <van-dialog :cancel-button-text="$t('CANCEL')" :confirm-button-text="$t('CONFIRM')" @confirm="isSelectm" v-model="show" show-cancel-button>
          <van-field v-model="selectm" size="large" type="number" :label="$t('BORRORWMONEY')" />
        </van-dialog>
      </div>
      <!-- <div class="sm">
        <span :class="{ action: item.active }" @click="sm(item)" class="smitem" v-for="(item, index) in smlist" :key="index">{{item.name}}</span>
      </div> -->
      <van-slider :step="stepSize" class="homeSlider" :min="sliderMix" :max="sliderMax" v-model="value" @input="onChange">
        <div class="sliderBtn" slot="button">
          <van-image class="sliderBtnImg" width="36" height="36" :src="sliderBtnImg" />
        </div>
      </van-slider>
      <div class="add" @click="add">
        <van-image class="addImg" width="36" height="36" :src="addImg" />
      </div>
      <div class="subtraction" @click="subtraction">
        <van-image class="subtractionImg" width="36" height="36" :src="subtractionImg" />
      </div>
      <div class="min">{{sliderMix}}{{$t('UNIT')}}</div>
      <div class="max">{{sliderMax}}{{$t('UNIT')}}</div>
    </div>
    <div class="mainContainer">
      <van-cell-group class="contentBox">
        <!-- 借款期限 -->
        <van-cell center class="borrowMoneyTimeBox">
          <div class="borrowMoneyTime" slot="title">{{$t('BORRORWMONEYTIME')}}</div>
          <div slot="label" class="selecBorrowMoneyTime">
            <div @click="changeActive(item, index)" :class="{ active: item.active }" v-for="(item, index) in selecBorrowMoneyTime_item" :key="index" class="selecBorrowMoneyTime_item">{{item.time}}</div>
          </div>
        </van-cell>
        <!-- 每期还款 -->
        <van-cell class="everyIssueBox" center>
          <div class="everyIssue" slot="title">{{$t('EVERYISSUE')}}</div>
          <div class="everyIssueMoneyBox" slot="default">
            <div class="everyIssueMoney">{{formatNumberWithDots(everyIssueMoney)}}{{$t('UNIT')}}</div>
            <!-- <span class="dayRateAndMonthlyinterest">{{$t('DAYRATE')}}{{rixi}}%{{$t('MOTHLYINTEREST')}}Rp{{fuwufei}}{{$t('UNIT')}}</span> -->
            <span class="dayRateAndMonthlyinterest">{{$t('DAYRATE')}}{{rixi}}%</span>
          </div>
        </van-cell>
        <div class="clause">
          <div class="checkbox float_leftAndColors">
            <van-checkbox icon-size="14px" disabled  checked-color="#FC7409" shape="square" v-model="checked"></van-checkbox>
          </div>
          <div class="consent float_leftAndColors">{{$t('CONSENT')}}</div>
          <div @click="DialogA" class="delegate float_leftAndColors">{{$t('AGREEMENTENT')}}</div>
          <div style="color:red" class="float_leftAndColors">{{$t('Pleasereviewtheprotocolcarefully')}}</div>
          <!-- <div @click="DialogB" class="agreementEnt float_leftAndColors">{{$t('AGREEMENTENT')}}</div>
          <div @click="DialogC" class="serviceAgreement float_leftAndColors">{{$t('SERVICEAGREEMENT')}}</div> -->
          <van-button @click="sub" color="#0097B2" class="sub" type="primary" block>{{$t('ATONECBORROWMONEY')}}</van-button>
        </div>
      </van-cell-group>
    </div>
    <Pop></Pop>
    <!-- <div class="messagebar">
      <vue-seamless-scroll
        :data="gd"
        :class-option="{
          direction: 0,
          step: 1
        }"
        class="warp"
      >
        <ul class="item">
          <li v-for="(item, index) in gd" :key="index">
            <span class="marle10">{{item.auditTime}}</span>
            <span class="marle10">{{item.phone || '+66xxxx'}}</span>
            <span class="marle10">Rp{{item.money}}</span>
            <img :src="require('./img/image-removebg-preview(5).png')" width="20" height="20" alt="" srcset="">
          </li>
        </ul>
      </vue-seamless-scroll>
    </div> -->
    <!-- <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <img :src="require('./img/l1.png')" height="100%" alt="" srcset="">
      </van-swipe-item>
      <van-swipe-item>
        <img :src="require('./img/l2.png')" height="100%" alt="" srcset="">
      </van-swipe-item>
      <van-swipe-item>
        <img :src="require('./img/l3.png')" height="100%" alt="" srcset="">
      </van-swipe-item>
    </van-swipe> -->
      <img class="adv" :src="require('./img/l1.png')" height="200" alt="" srcset="">
      <img class="adv" :src="require('./img/b1.png')" width="100%" height="200" alt="" srcset="">
      <img class="adv" :src="require('./img/b2.png')" width="100%" height="200" alt="" srcset="">
      <!-- <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in advimg" :key="index">
          <van-image class="adv" :src="item.imageUrl" />
        </van-swipe-item>
      </van-swipe> -->
  </div>
</template>

<script>
// import { getItem } from '@/utils/storage'
import { getLoanConfig, getadv, save, setRegistrationID } from '@/api/home'
// import vueSeamlessScroll from 'vue-seamless-scroll'
import { Toast, Dialog } from 'vant'
import { myInfo } from '@/api/my'
import './css/index.css'
import Pop from './components/pop.vue'
export default {
  name: 'home',
  data () {
    return {
      // 滑块用的值
      value: 10000,
      // 滑块步长
      stepSize: 10000,
      // 滑块图片
      sliderBtnImg: require('./img/pig.png'),
      // 加图片
      addImg: require('./img/plus.png'),
      // 减图片
      subtractionImg: require('./img/subtract.png'),
      // 滑块最小值
      sliderMix: 5000,
      // 滑块最大值
      sliderMax: 100000,
      // 每次加减固定的值
      num: 10000,
      // 可选择月份列表
      selecBorrowMoneyTime_item: [
        // {
        //   time: '1' + 'เดือน',
        //   active: true,
        //   moth: 1
        // },
        // {
        //   time: '3' + 'เดือน',
        //   active: false,
        //   moth: 3
        // },
        // {
        //   time: '6' + 'เดือน',
        //   active: false,
        //   moth: 6
        // },
        // {
        //   time: '12' + 'เดือน',
        //   active: false,
        //   moth: 12
        // },
        // {
        //   time: '24' + 'เดือน',
        //   active: false,
        //   moth: 24
        // },
        // {
        //   time: '36' + 'เดือน',
        //   active: false,
        //   moth: 36
        // }
      ],
      // 每期还款余额
      everyIssueMoney: 5000,
      // 默认月份
      month: 1,
      // 服务费
      fuwufei: 0,
      // 日利率
      rixi: 0,
      // 费率字符串常数
      FEILVStr: '0.9,0.91,0.92,0.93,0.94,0.95,0.96,0.97,0.98,0.99,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.9,0.91,0.92,0.93,0.94,0.95,0.96,0.97,0.98,0.99,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.9,0.91,0.92,0.93,0.94,0.95,0.96,0.97,0.98,0.99,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.9,0.91,0.92,0.93,0.94,0.95,0.96,0.97,0.98,0.99,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.9,0.91,0.92,0.93,0.94,0.95,0.96,0.97,0.98,0.99,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.9,0.91,0.92,0.93,0.94,0.95,0.96,0.97,0.98,0.99,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,',
      // 条款的复选框
      checked: true,
      // monthArr 月份数组
      monthArr: null,
      // 条款消息
      A: `Ketentuan dan kontrak:

(1) Setelah peminjam menerima batas kredit. Jangan digunakan untuk hal-hal ilegal/perjudian/narkoba, dll dan pelanggaran lainnya, dll.

(2) Sertifikasi barang harus diselesaikan dalam waktu 1 hari, jika peminjam tidak menyelesaikan sertifikasi agunan dalam waktu yang ditentukan, Perusahaan harus menyerahkan dokumen kepada pengacara untuk campur tangan dalam ujian awal pengajaran. dan akan menghubungi referensi untuk menyelidiki terlebih dahulu.

(3) Setelah mendapat persetujuan bagi peminjam baru, mereka harus menyimpan surat berharga sebagai jaminan atau membeli asuransi kredit dari perusahaan untuk memeriksa likuiditas atau kelayakan kredit pelanggan baru yang ingin menerima pinjaman dari kami. bahwa dia akan mampu melunasi utangnya secara mencicil (Bagi peminjam yang mempunyai riwayat mencicil dengan perusahaan sebelumnya, tidak perlu menggunakan agunan atau asuransi kredit)

(4) Pada saat kontrak tersebut ditandatangani Jika perusahaan menemukan aktivitas yang tidak normal atau masuk daftar hitam, dll., perusahaan berhak untuk menangguhkan sementara pinjaman peminjam. Periksa dan temukan solusi atas masalah tersebut dalam waktu 3 hari sejak pemberitahuan, jika tidak, perusahaan berhak menolak kontrak ini.


(5) Peminjam berjanji jika peminjam menyetujui perjanjian tersebut Tidak mematuhi kontrak sama sekali. Pemberi pinjaman berhak mengakhiri kontrak dan meminta peminjam membayar pokok ditambah bunga. Segera kembalikan ke pemberi pinjaman. dan peminjam bersedia membayar segala kerusakan dalam menelepon dan menuntut Termasuk biaya pengacara dan berbagai biaya perkara terkait gugatan tersebut.
          Apabila peminjam ingin membatalkan kontrak, maka perlu membayar jumlah pinjaman sebesar 30% - 50% dari jumlah pinjaman.Pembayaran harus dilakukan kepada pemberi pinjaman dan peminjam harus mulai bertindak untuk mencapai tujuan. tujuan kontrak ini Kontrak ini Dilindungi oleh hukum`,
      // 滚动
      gd: null,
      // 首页广告
      advimg: [],
      // 显示弹窗
      show: false,
      // 选择金额里面得钱
      selectm: null,
      // 选项金额
      smlist: [
        // {
        //   name: '5Jt',
        //   money: 5,
        //   active: false
        // },
        {
          name: '20Jt',
          money: 20,
          active: false
        },
        {
          name: '50Jt',
          money: 50,
          active: false
        },
        {
          name: '100JT',
          money: 100,
          active: false
        },
        {
          name: '1M',
          money: 1000,
          active: false
        },
        {
          name: '10M',
          money: 10000,
          active: false
        }
      ]
    }
  },
  computed: {
    // 申请金额
    applyMoney () {
      // return Number(this.value).toFixed(2)
      return this.formatNumberWithDots(this.value)
    }
  },
  methods: {
    formatNumberWithDots (number) {
      // 自定义 Intl.NumberFormat 格式选项，使其支持逗号作为千位分隔符
      let str = number.toString() // 将数字转换为字符串
      const pattern = /(-?\d+)(\d{3})/ // 创建正则表达式模式
      while (pattern.test(str)) {
        str = str.replace(pattern, '$1.$2') // 插入逗号
      }
      return str
    },
    // 首页广告
    async loadv () {
      const { data } = await getadv({
        type: '3'
      })
      if (data.code === 200) {
        this.advimg = data.data
      }
    },
    // 滑块用的方法
    async loadata () {
      try {
        const { data } = await getLoanConfig()
        // console.log(data.data)
        // 初始值
        this.value = Number(data.data.defaultAmount)
        // 初始月份
        this.month = Number(data.data.defaultMonth)
        // selec月份
        const arr = []
        const monthArr = data.data.month.split(',')
        this.monthArr = monthArr
        for (const key in monthArr) {
          const obj = {}
          obj.time = monthArr[key] + 'bulan'
          obj.active = false
          obj.moth = Number(monthArr[key])
          arr.push(obj)
        }
        for (const k in arr) {
          if (arr[k].moth === this.month) {
            arr[k].active = true
            break
          }
        }
        // console.log(monthArr)
        this.selecBorrowMoneyTime_item = arr
        // 比例
        this.FEILVStr = data.data.serviceCharge
        this.feilv(this.month)
        this.rililv(this.month)
        this.everyIssueMoneyfunc(this.month)
        // 最小值
        this.sliderMix = data.data.min
        // 最大值
        this.sliderMax = data.data.max
        this.smlist.forEach((i) => {
          i.active = false
          if (this.value === Number(i.money * 1000000)) {
            i.active = true
          }
        })
      } catch (err) {
        Toast.loading({
          message: this.$t('PLEALOGIN'),
          forbidClick: true,
          loadingType: 'spinner'
        })
        setTimeout(() => {
          this.$router.push({
            name: 'login'
          })
        }, 3000)
      }
    },
    onChange (value) {
      this.everyIssueMoneyfunc(this.month)
      this.feilv(this.month)
      this.rililv(this.month)
      this.everyIssueMoneyfunc(this.month)
    },
    // 加
    add () {
      if (this.value >= this.sliderMax) return
      this.value += this.num
      this.feilv(this.month)
      this.rililv(this.month)
      this.everyIssueMoneyfunc(this.month)
    },
    // 减
    subtraction () {
      if (this.value <= this.sliderMix) return
      this.value -= this.num
      this.feilv(this.month)
      this.rililv(this.month)
      this.everyIssueMoneyfunc(this.month)
    },
    // 改变借款期限的颜色
    changeActive (item, index) {
      for (const i in this.selecBorrowMoneyTime_item) {
        this.selecBorrowMoneyTime_item[i].active = false
      }
      item.active = true
      this.month = item.moth
      this.feilv(this.month)
      this.rililv(this.month)
      this.everyIssueMoneyfunc(this.month)
    },
    // 服务费
    feilv (moth) {
      // 服务费_数组
      const fuwufeiArr = this.FEILVStr.split(',')
      const money = Number(this.value).toFixed(2)
      let index = 0
      for (const key in this.monthArr) {
        if (this.monthArr[key] === String(moth)) {
          index = key
          break
        }
      }
      let fuwufei = money * fuwufeiArr[index]
      fuwufei = fuwufei.toFixed(2)
      this.fuwufei = fuwufei
    },
    // 日利率
    rililv (moth) {
      // 服务费_数组
      const fuwufeiArr = this.FEILVStr.split(',')
      let index = 0
      for (const key in this.monthArr) {
        if (this.monthArr[key] === String(moth)) {
          index = key
          break
        }
      }
      let tmpval = fuwufeiArr[index] * 100
      tmpval = tmpval.toFixed(2)
      this.rixi = tmpval
    },
    // 月供
    everyIssueMoneyfunc (moth) {
      const money = (Number(this.value) / moth) + Number(this.fuwufei)
      this.everyIssueMoney = money.toFixed(2)
    },
    // 弹窗
    DialogA () {
      Dialog.alert({
        title: this.$t('AGREEMENTENT'),
        message: this.A,
        confirmButtonText: this.$t('CONFIRM')
      }).then(() => {
        // on close
      })
    },
    // 立即借款
    sub () {
      if (!this.checked) {
        Toast(this.$t('CONSENTPROTOCOL'))
        return false
      }
      this.$router.push({
        name: 'loanapplication',
        params: {
          month: this.month,
          money: this.value
        }
      })
    },
    // 滚动
    async loadgundong () {
      // const { data } = await successList()
      // this.gd = data.data
      // console.log(data)
      const transactions = this.generateTransactionsList(100)
      this.gd = transactions
      console.log(transactions)
    },
    generateRandomDate () {
      const now = new Date()
      const month = now.getMonth() + 1 // 月份是从0开始的，所以需要加1
      const day = now.getDate()

      // 格式化为两位数
      const monthStr = month.toString().padStart(2, '0')
      const dayStr = day.toString().padStart(2, '0')

      // 组合成月日字符串
      const monthDay = `${monthStr}-${dayStr}`
      return monthDay
    },

    generateRandomPhoneNumber () {
      // 生成类似"0811****496"的电话号码
      const prefix = '08'
      const middle = '******'
      const suffix = Math.floor(1000 + Math.random() * 9000).toString().padStart(4, '0')
      return prefix + middle + suffix
    },

    generateRandomAmount () {
      // 生成20000000到80000000之间的随机数（因为需要乘以10，所以范围缩小到8000000）
      const randomValue = Math.random() * 6000000 + 2000000
      // 取整并乘以10来确保是10的倍数
      const amount = Math.round(randomValue) * 10
      return amount
    },

    generateTransaction () {
      const auditTime = this.generateRandomDate() // 生成2020-01-01到2025-01-01之间的日期
      const money = this.generateRandomAmount()
      const phone = this.generateRandomPhoneNumber()
      return {
        auditTime,
        money,
        phone
      }
    },
    generateTransactionsList (count) {
      const transactions = []
      for (let i = 0; i < count; i++) {
        transactions.push(this.generateTransaction())
      }
      return transactions
    },
    // 获取通讯录
    async loadmy () {
      const that = this
      window.BUFANAPP.contactAll(function contactOneBack (data) {
        if (data) {
          // alert(data)
          const phoneArray = []
          // result: Array
          const result = JSON.parse(data).contacts
          // alert(result)
          for (let index = 0; index < result.length; index++) {
            if (result[index].phones[0]) {
              // alert(result[index].phones[0])
              const phone = that.checkName(result[index].phones[0])
              // alert(phone + '--phone')
              phoneArray.push(Number(phone))
            }
          }
          that.loadsave(phoneArray)
        }
      })
    },
    async loadsave (phoneList) {
      const { data } = await save({
        phoneList
      })
      console.log(JSON.stringify(data))
    },
    async loadsetRegistrationID (id) {
      const { data } = await setRegistrationID({
        id
      })
      console.log(data)
      // alert(JSON.stringify(data))
    },
    openM () {
      this.show = !this.show
    },
    isSelectm () {
      if (Number(this.selectm) < this.sliderMix) {
        this.selectm = Number(this.sliderMix)
      } else if (Number(this.selectm) > this.sliderMax) {
        this.selectm = Number(this.sliderMax)
      }
      this.selectm = Number(this.selectm) - (Number(this.selectm) % 100)
      this.value = Number(this.selectm)
      this.smlist.forEach((i) => {
        i.active = false
        if (this.value === Number(i.money * 1000000)) {
          i.active = true
        }
      })
      this.feilv(this.month)
      this.rililv(this.month)
      this.everyIssueMoneyfunc(this.month)
    },
    sm (item) {
      this.smlist.forEach((i) => {
        // console.log(i)
        i.active = false
      })
      item.active = true
      this.value = Number(item.money * 1000000)
      this.feilv(this.month)
      this.rililv(this.month)
      this.everyIssueMoneyfunc(this.month)
    },
    // 去除多余的字符
    checkName (val) {
      var reg = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？+-]")
      var rs = ''
      for (var i = 0; i < val.length; i++) {
        rs = rs + val.substr(i, 1).replace(reg, '')
      }
      // console.log(l)
      return rs
    },
    // 判断是不是在APP内
    isApp () {
      if (this.getQueryString('download') !== '1') {
        // console.log(this.getQueryString('download') !== '1')
        this.isLogin().then((data) => {
          if (data) {
            if (!window.isApp()) {
              this.isAndroidOrIOS()
            }
          }
        })
      } else {
        return false
      }
    },
    getQueryString (name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) return unescape(r[2])
      return null
    },
    // 判断是什么系统
    isAndroidOrIOS () {
      var u = navigator.userAgent
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (isAndroid) {
        // 安卓
        // location.href = 'http://download.qd5.cc/'
        return false
      }
      if (isiOS) {
        // 苹果
        return false
      }
      return false
    },
    // 是否登录
    async isLogin () {
      return await myInfo()
    }
  },
  created () {
    this.loadata()
    this.loadgundong()
    this.loadv()
    this.loadmy()
    this.isApp()
  },
  components: {
    Pop
    // vueSeamlessScroll
  }
}
</script>

<style scoped>
.my-swipe {
  height: 200px;
  width: 100%;
}
.my-swipe img {
  width: 100%;
  height: 100%;
}
.marle10 {
  margin: 0 4px;
  color: #FC7409;
}
.headContainer {
  position: relative;
  height: 200px;
  /* background: linear-gradient(to bottom,#0097B2,#0097B2) #0097B2; */
  /* background: url('./img/home-head-bg.png') no-repeat center; */
  /* background-size: cover; */
  background-color: #0097B2;
}
.applyMoney,
.money {
  text-align: center;
  color: #000;
}
.apply {
  width: 75%;
  margin: 0 auto;
  font-size: 30px;
  color: #000;
}
.applyMoney {
  padding-top: 25px;
  font-size: 19px;
}
.money {
  margin-top: 22px;
  font-size: 35px;
  display: flex;
  justify-content: center;
}
.showm {
  /* margin-left: 70px; */
}
.chooseMoney {
  border-radius: 5px;
  padding: 5px;
  width: 60px;
  margin-left: 20px;
  background-color: #0097B2;
  border: 0.02667rem solid #fff;
  color: #000;
}
.min,
.max {
  position: absolute;
  font-size: 12px;
  color: rgba(255,255,255,.6);
}
.min {
  left: 60px;
  bottom: 8px;
}
.max {
  right: 60px;
  bottom: 8px;
}
.borrowMoneyTime {
  font-size: 16px;
}
.selecBorrowMoneyTime_item {
  box-sizing: border-box;
  float: left;
  margin-left: 5px;
  width: 50px;
  height: 35.5px;
  font-size: 15px;
  color: #999;
  background-color: #f5f5f9;
  border: 0;
  border-radius: 4px;
  text-align: center;
  line-height: 35.5px;
}
.active {
  background-color: #0097B2;
  color: #000;
}
.everyIssue,
.everyIssueMoney {
  font-size: 16px;
}
.everyIssueMoney {
  color: #000;
}
.dayRateAndMonthlyinterest {
  /* color: #FABD03; */
  font-size: 12px;
}
.float_leftAndColors {
  float: left;
  margin-left: 5px;
  color: #FC7409;
}
.checkbox {
  padding-top: 3px;
}
.consent {
  color: unset;
}
.delegate {
  font-size: 12px;
}
.clause {
  box-sizing: border-box;
  padding: 12px;
  font-size: 13px;
  /* height: 100px; */
  background-color: #fff;
}
.sub {
  margin-top: 30px;
  font-size: 18px;
}
.notice-swipe {
  height: 40px;
  line-height: 40px;
}
.adv {
  width: 100%;
  height: 200px;
  display: block;
}
.sm {
  margin-left: 5px;
  margin-top: 10px;
  /* word-wrap: break-word; */
}
.smitem {
  margin: 0 8px;
  box-sizing: border-box;
  padding: 10px;
  font-size: 15px;
  color: #999;
  background-color: #f5f5f9;
  border-radius: 5px;
}
.home >>> .van-button--default {
  border: 3px solid #eee;
}
.warp {
  height: 80px;
  width: 360px;
  margin: 0 auto;
  overflow: hidden;
}
.warp ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
}
.warp ul li, .warp ul li a {
  display: block;
  height: 30px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}
.home {
  padding-bottom: 80px;
}
.action {
  background-color: #0097B2;
  color: #000;
}
</style>
